import { Component, Injector, QueryList, ViewChildren } from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { MultiBorrowersAddressHistoryStep } from '../../../models/wizard/multiborrowers-address-history-step.model';
import { BorrowerAddressHistoryComponent } from '../../borrower-address-history/borrower-address-history.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import * as _ from 'lodash';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { StepType } from '../../../models/wizard/config/step-type.enum';
import { ResidencyBasis } from '../../../models/enums/residency-basis.enum';
import { ResidencyAddress } from '../../../models/residency-address.model';
import { RealEstateOwned } from '../../../models/mortgage.model';

@Component({
  selector: 'multiborrowers-address-history',
  templateUrl: 'multiborrowers-address-history-step.component.html'
})

export class MultiBorrowersAddressHistoryComponent extends WizardStepComponentBase<MultiBorrowersAddressHistoryStep> {

  @ViewChildren('borrowerAddressHistory') borrowerAddressHistoryComponents: QueryList<BorrowerAddressHistoryComponent> | undefined;

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];
  mortgage: MortgageApplication;

  reoFieldsToConfig: any = {};

  isStepBeingEdited: boolean = false;

  private _invalidBorrowerAddressComponent: BorrowerAddressHistoryComponent;

  constructor(private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.mortgage = this.mortgageApplication;
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
        this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }

    this.isStepBeingEdited = this.wizardFlowService.isEditEnabled;

    const reoStepConfig = this.wizardFlowService.activeFlowConfig.steps.find(s => s.stepType === StepType.ReoStep);
    if (reoStepConfig) {
      const reoStep = this.wizardFlowService.getStepById(reoStepConfig.id);
      if (reoStep) {
        this.reoFieldsToConfig = reoStep.fieldConfig;
      }
    }
  }

  onNextClicked() {
    let valid = true;
    this._invalidBorrowerAddressComponent = null;
    if (this.borrowerAddressHistoryComponents) {
      for (let borrowerAddressHistoryComponent of this.borrowerAddressHistoryComponents) {
        valid = borrowerAddressHistoryComponent.validate() && valid;
        if (!valid && !this._invalidBorrowerAddressComponent) {
          this._invalidBorrowerAddressComponent = borrowerAddressHistoryComponent;
        }
      }
    }
    if (valid) {
      super.onNextClicked();
    } else {
      this._invalidBorrowerAddressComponent.scrollToFirstInvalid();
    }
  }

  onCopyFromPrimaryBorrowerClicked = (toBorrower: Borrower) => {
    const borrower = this.coBorrowers.find(b => b.borrowerId === toBorrower.borrowerId);
    borrower.residencyAddresses = [];
    this.mainBorrower.residencyAddresses.forEach(residencyAddress => {
      if (residencyAddress.residencyBasis === ResidencyBasis.Own) {
        const reoForThatAddress = this.findAlreadyExistingReoInMortgage(residencyAddress);
        if (reoForThatAddress) {
          // There is already an REO added for the borrower, gotta add the other borrower's id to owningBorrowerIds
          if (!reoForThatAddress.owningBorrowerIds.includes(toBorrower.borrowerId)) {
            reoForThatAddress.owningBorrowerIds.push(toBorrower.borrowerId);
          }
        }
      }
      const residencyAddressToCopy = { ...residencyAddress, residencyAddressId: null, addressId: null };
      residencyAddressToCopy.address.addressId = null;
      toBorrower.residencyAddresses.push(residencyAddressToCopy);
    });
    this.coBorrowers = _.cloneDeep(this.coBorrowers);
  }

  private findAlreadyExistingReoInMortgage = (address: ResidencyAddress): RealEstateOwned => {
    const reo = this.mortgage?.realEstateOwned.find(reo =>
      reo.address1.toLocaleLowerCase() === address.address?.address1?.toLocaleLowerCase() &&
      reo.city.toLocaleLowerCase() === address.address?.city?.toLocaleLowerCase() &&
      reo.state === address.address?.state &&
      reo.zipCode === address.address?.zipCode);

    return reo;
  }
}
