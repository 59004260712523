import { LoanDocTask } from "./loan-doc-task.model";

export class DocumentSigningOrder {
  documentSigningOrderId: number;
  documentSigningVendor: DocumentSigningVendor = DocumentSigningVendor.DocuSign;
  applicationId: number;
  filePackageId: number;
  status: string;
  thirdPartyOrderId: string;
  envelopeToken: string;
  accountId: string;
  senderViewUrl: string;
  signingLinkSentInEmail: boolean;
  loanDocTasks: LoanDocTask[];
  recipients: DocumentSigningRecipient[];
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export class DocumentSigningRecipient {
  documentSigningRecipientId: number;
  documentSigningOrderId: number;
  userId: string;
  name: string;
  email: string;
  borrowerId: number;
  internalContactId: number;
  externalContactId: number;
  loanDocTaskId: number;
  recipientType: string;
  thirdPartyRecipientId: string;
  status: string;
  companyId: number;
  insertedBy: string;
  dateInserted: string;
  updatedBy: string;
  dateUpdated: string;
}

export enum DocumentSigningVendor {
  DocuSign = "DocuSign",
  DocMagic = "DocMagic",
  EncompassPOS = "EncompassPOS"
}
