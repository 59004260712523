import {
  Component,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Borrower } from '../../../models/borrower-model';
import { AddressHistoryStep } from '../../../models/wizard/address-history-step.model';
import { EnumsService } from '../../../services/enums.service';
import { BorrowerAddressHistoryComponent } from '../../borrower-address-history/borrower-address-history.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { StepType } from '../../../models/wizard/config/step-type.enum';
import { MortgageApplication } from '../../../models/mortgage-app.model';

@Component({
  selector: 'current-address-history',
  templateUrl: 'current-address-history.component.html',
})
export class CurrentAddressHistoryComponent
  extends WizardStepComponentBase<AddressHistoryStep>
  implements OnInit {
  @ViewChild('borrowerAddressHistory') borrowerAddressHistoryComponent:
    | BorrowerAddressHistoryComponent
    | undefined;

  mainBorrower!: Borrower;

  mortgage: MortgageApplication;

  reoFieldsToConfig: any = {};

  constructor(private readonly _injector: Injector,
    private readonly _enumsService: EnumsService) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
    }
    this.mortgage = this.mortgageApplication;

    const reoStepConfig = this.wizardFlowService.activeFlowConfig.steps.find(s => s.stepType === StepType.ReoStep);
    if (reoStepConfig) {
      const reoStep = this.wizardFlowService.getStepById(reoStepConfig.id);
      if (reoStep) {
        this.step.fieldConfig = { ...this.step.fieldConfig, ...reoStep.fieldConfig };
        this.reoFieldsToConfig = this.step.fieldConfig;
      }
    }
  }

  onNextClicked() {
    let valid = this.borrowerAddressHistoryComponent?.validate();
    if (valid) {
      super.onNextClicked();
    }
  }
}
