import { Injectable } from '@angular/core';
import { WizardFlowContext } from '../../../wizard-flow-context.model';
import { GenericStepFactory } from './generic-step.factory';
import { NextStepDeciderFactory } from './next-step-decider.factory';
import { ICreateStep } from './step-factory.interface';
import { GatherLeadSourceStep } from '../../gather-lead-source-step.model';
import { GatherLeadSourceStepConfig } from '../gather-lead-source-step-config.model';

@Injectable()
export class GatherLeadSourceStepFactory
  extends GenericStepFactory<GatherLeadSourceStep>
  implements ICreateStep
{
  constructor(nextStepDeciderFactory: NextStepDeciderFactory) {
    super(nextStepDeciderFactory, GatherLeadSourceStep);
  }

  create(
    config: GatherLeadSourceStepConfig,
    wizardFlowContext: WizardFlowContext
  ): GatherLeadSourceStep {
    let gatherLeadSourceStep = super.create(config, wizardFlowContext);
    gatherLeadSourceStep.pickAgentReferralFromAList =
      config.pickAgentReferralFromAList != null
        ? config.pickAgentReferralFromAList
        : true;

    if (!config.leadSourceOptions) {
      gatherLeadSourceStep.leadSourceOptions =
        GatherLeadSourceStepConfig.allLeadSourceOptions;
    } else {
      gatherLeadSourceStep.leadSourceOptions = [...config.leadSourceOptions];
    }

    return gatherLeadSourceStep;
  }

  configure(
    step: GatherLeadSourceStep,
    config: GatherLeadSourceStepConfig
  ): void {
    super.configure(step, config);
    config.pickAgentReferralFromAList = step.pickAgentReferralFromAList;

    if (!step.leadSourceOptions) {
      config.leadSourceOptions = GatherLeadSourceStepConfig.allLeadSourceOptions;
    } else {
      config.leadSourceOptions = [...step.leadSourceOptions];
    }
  }
}
