<ng-template #currentAddress>
  <form #addressForm="ngForm" novalidate id="addressForm" name="addressForm">
    <div class="row mb-5 mt-10 rounded mb-6" *ngIf="isRefinance"
      [ngClass]="{
        'is-invalid' : sameAsCurrentAddress && sameAsCurrentAddress.touched && sameAsCurrentAddress.invalid,
        'p-4' : sameAsCurrentAddress && sameAsCurrentAddress.touched && sameAsCurrentAddress.invalid,
        'border' : sameAsCurrentAddress && sameAsCurrentAddress.touched && sameAsCurrentAddress.invalid,
        'border-danger' : sameAsCurrentAddress && sameAsCurrentAddress.touched && sameAsCurrentAddress.invalid
      }">
      <div class="col-sm-6 fs-6 text-dark" style="padding-top: 4px;">Is the property you're refinancing the same as your
        current address?
      </div>
      <div class="col-md-1">
        <div class="form-check form-check-custom form-check-solid">
          <input class="form-check-input" name="sameAsCurrentAddress" required [value]="true" type="radio" id="aYes"
            [(ngModel)]="refiPropertySameAsCurrentAddress" #sameAsCurrentAddress="ngModel"
            (ngModelChange)="onAddressQuestionAnswered()" />
          <label style="padding-right: 6px;" class="form-check-label" for="aYes">Yes</label>
        </div>
      </div>
      <div class="col-md-1">
        <div class="form-check form-check-custom form-check-solid">
          <input class="form-check-input" name="sameAsCurrentAddress" required [value]="false" type="radio" id="aNo"
            [(ngModel)]="refiPropertySameAsCurrentAddress" #sameAsCurrentAddress="ngModel"
            (ngModelChange)="onAddressQuestionAnswered()"/>
          <label style="padding-right: 6px;" class="form-check-label" for="aNo">No</label>
        </div>
      </div>
    </div>
    <div class="invalid-feedback">Please, answer the question above. We need to make sure your refinance address is correct.</div>
  </form>
  <div *ngIf="refiPropertySameAsCurrentAddress === false && isRefinance" class="fs-6 text-dark mb-8"> Then, please enter your current address </div>
  <div *ngIf="refiPropertySameAsCurrentAddress && isRefinance" class="fs-6 text-dark mb-8"> Great! Please make sure it's the address below </div>

  <address [showCountry]="true" *ngIf="!isRefinance || (isRefinance && refiPropertySameAsCurrentAddress !== undefined)" [address]="address!"></address>
</ng-template>

<wizard-step-template [stepMainTemplate]="currentAddress" [step]="step" [stepComponent]="this"></wizard-step-template>
