import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MortgageApplication } from '../../../models/mortgage-app.model';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { map, Observable, of } from 'rxjs';
import { tap } from "rxjs/operators";
import { GatherLeadSourceStep } from '../../../models/wizard/gather-lead-source-step.model';
import { Option } from '../../../models/wizard/option.model';
import { GatherLeadSourceStepConfig } from '../../../models/wizard/config/gather-lead-source-step-config.model';

@Component({
  selector: 'gather-lead-source-step',
  templateUrl: 'gather-lead-source-step.component.html',
})
export class GatherLeadSourceStepComponent
  extends WizardStepComponentBase<GatherLeadSourceStep>
  implements OnInit, AfterViewInit
{
  @ViewChild('leadSourceForm') leadSourceForm: NgForm | undefined;

  mortgage: MortgageApplication;

  leadSource: string = null;

  leadSourceType: string = null;

  leadSourceAgent: number = null;

  protected referralAgents: AgentItem[] = null;

  protected companies: string[] = [];

  protected referralSourceLabel = 'Referral Source';

  protected availableLeadSourceOptions: Option[] =
    GatherLeadSourceStepConfig.allLeadSourceOptions;

  protected noOfCharactersLeft = 50;

  protected valuesOfOptionsSelectedToBePresentedToUserAsChoices: string[] = [];

  constructor(injector: Injector) {
    super(injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.mortgage = this.mortgageApplication;
  }

  ngOnInit() {
    super.ngOnInit();
    this.populateAvailableOptions();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initializeLeadSource();
  }

  onLeadSourceChangedType = () => {
    this.leadSource = null;
    this.referralSourceLabel = this.getReferralSourceLabel();
    if (
      this.leadSourceType === 'Agents' &&
      this.step.pickAgentReferralFromAList
    ) {
      this.startSpinner();
      const needToAddICannotFindMyAgent = !!!this.referralAgents;
      this.populateLeadSourceAgentOptions()
        .subscribe(() => {
          if (needToAddICannotFindMyAgent) {
            this.referralAgents.unshift({
              id: -1,
              name: 'I cannot find my agent on this list',
              company: '',
            });
          }
        })
        .add(() => this.stopSpinner());
    } else {
      this.leadSourceAgent = null;
    }
  };

  onAgentChanged = () => {
    this.referralSourceLabel = this.getReferralSourceLabel();
    if (this.leadSourceAgent === -1) {
      this.leadSource = null;
    }
  };

  onLeadSourceTextChanged = () => {
    this.noOfCharactersLeft = 50 - this.leadSource.length;
  };

  onKeyDown = (e: any) => {
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'F12') {
      return;
    }
    if (this.noOfCharactersLeft <= 0) {
      e.preventDefault();
    }
  };

  protected optionsSelectedToPresentToUserChanged = (
    option: Option,
    e: any
  ) => {
    if (e.target.checked) {
      if (
        !this.step.leadSourceOptions.find(
          (o) => o.name.toLowerCase() === option.name.toLowerCase()
        )
      ) {
        this.step.leadSourceOptions.push(option);
      }
    } else {
      const index = this.step.leadSourceOptions.findIndex(
        (o) => o.name.toLowerCase() === option.name.toLowerCase()
      );
      if (index >= 0) {
        this.step.leadSourceOptions.splice(index, 1);
      }
    }
  };

  private populateAvailableOptions = () => {
    this.availableLeadSourceOptions = GatherLeadSourceStepConfig.allLeadSourceOptions;
    this.valuesOfOptionsSelectedToBePresentedToUserAsChoices =
      this.step.leadSourceOptions.map((o) => o.name.toLowerCase());
  };

  private getReferralSourceLabel = (): string => {
    if (this.leadSourceType === 'Real Estate Agent') {
      return 'Who is the Company/Agent?';
    } else if (this.leadSourceType === 'Social Media') {
      return 'Which Platform?';
    } else if (this.leadSourceType === 'Internet') {
      return 'Which Website?';
    } else if (this.leadSourceType === 'Past Clients') {
      return 'Who Referred You?';
    } else if (this.leadSourceType === 'Other') {
      return 'Please Specify';
    }
    return 'Referral Source';
  };

  onNextClicked() {
    this.wizardFlowService.context.leadSource = this.leadSource || this.leadSourceType;
    this.wizardFlowService.context.leadSourceType = this.leadSourceType;
    if (this.leadSourceType === 'Agents') {
      this.leadSource =
        this.leadSourceAgent == -1 || this.leadSourceAgent == null
          ? this.leadSource
          : this.leadSourceAgent?.toString();
      this.wizardFlowService.context.leadSource = this.leadSource;
    }
    const valid = this.validate();
    if (valid) {
      // The presence of applicationInfo is an indicator that we are in the 'apply' flow.
      if (this.applicationInfo) {
        if (
          (this.leadSourceType === 'Agents' &&
            (this.leadSourceAgent == -1 || this.leadSourceAgent == null)) ||
          this.leadSourceType !== 'Agents'
        ) {

          if (this.leadSource) {
            this.applicationInfo.leadSource = `${this.leadSourceType}-${this.leadSource}`;
          } else {
            this.applicationInfo.leadSource = this.leadSourceType;
          }
        }

        this.startSpinner();
        this.mortgageApplicationService
          .saveLeadSource(
            this.mortgageApplication.applicationId,
            this.applicationInfo.leadSource
          )
          .subscribe(
            (result) => {
              this.stopSpinner();
              super.onNextClicked();
            },
            (error) => {
              this.stopSpinner();
            }
          );
      } else {
        this.saveMortgageApplicationBeforeNextStep = false;
        super.onNextClicked();
      }
    }
  }

  private initializeLeadSource = () => {
    if (this.applicationInfo) {
      const leadSourceTypeAndSource = this.applicationInfo.leadSource;

      if (leadSourceTypeAndSource) {
        const parts = leadSourceTypeAndSource.split('-');
        if (parts.length > 1) {
          this.leadSourceType = parts[0];
          if (parts.length >= 2) {
            this.leadSource = parts[1];
          }
        } else if (parts.length === 1) {
          this.leadSourceType = parts[0];
          this.leadSource = this.leadSourceType;
        }
      }
    } else {
      this.leadSource = this.wizardFlowService.context.leadSource;
      this.leadSourceType = this.wizardFlowService.context.leadSourceType;
    }

    if (!this.leadSource) {
      this.leadSource = null;
      this.leadSourceAgent = null;
    }

    if (
      this.leadSourceType === 'Agents' &&
      this.step.pickAgentReferralFromAList
    ) {
      this.startSpinner();
      this.populateLeadSourceAgentOptions()
        .subscribe(() => {
          if (isNaN(this.leadSource as any)) {
            this.leadSourceAgent = -1;
          } else {
            this.leadSourceAgent = Number(this.leadSource);
          }
          // Insert an item in the first index
          this.referralAgents.unshift({
            id: -1,
            name: 'I cannot find my agent on this list',
            company: '',
          });
        })
        .add(() => this.stopSpinner());
    }
  };

  private populateLeadSourceAgentOptions = (): Observable<void> => {
    if (this.referralAgents) {
      return of(undefined);
    }

    return this.mortgageApplicationService
      .getReferralAgents(this.mortgage.applicationId)
      .pipe(
        tap((referralSources) => {
          const { companies, agents } = _.orderBy(
            referralSources,
            ['orgName', 'lasName'],
            ['asc', 'asc']
          ).reduce(
            (acc, agent) => {
              const company = agent.orgName;

              acc.companies.add(company);

              acc.agents.push({
                id: agent.agentId,
                name: `${agent.firstName} ${agent.lastName}`,
                company: company,
              });
              return acc;
            },
            {
              companies: new Set<string>(),
              agents: new Array<AgentItem>(),
            }
          );

          this.companies = Array.from(companies);
          this.referralAgents = agents;
        }),
        map((_) => undefined)
      );
  };

  private validate = (): boolean => {
    if (this.leadSourceForm) {
      this.leadSourceForm.form.markAllAsTouched();
      if (!this.leadSourceForm.form.valid) {
        return false;
      }
    }
    return true;
  };
}

interface AgentItem {
  id: number;
  name: string;
  company: string;
}

