import { Address } from "./address-model";
import { ResidencyBasis } from "./enums/residency-basis.enum";

export class ResidencyAddress {
  addressId: number;
  residencyAddressId: number | undefined | null;
  residencyBasis: ResidencyBasis | undefined | null = null;
  residencyType: string = "";
  durationYears: number | undefined;
  durationMonths: number | undefined;
  fromCreditReport: boolean = false;
  rent: number = 0;
  address: Address | undefined;

  constructor(residencyType: string) {
    this.residencyType = residencyType;
    this.address = new Address();
    this.address.country = "us";
  }
}
