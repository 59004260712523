import { Injectable } from "@angular/core";
import { EnumerationItem } from "projects/shared/models/enumeration-item.model";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AssetType } from "../models/enums/asset-type-enum";
import { DataService } from "./data.service";

export interface CountryEnumerationItem {
  name: string;
  alpha2: string;
  alpha3: string;
  countryCode: string;
  iso_3166_2: string;
  region: string;
  subRegion: string;
  intermediateRegion: string;
  regionCode: string;
  subRegionCode: string;
  intermediateRegionCode: string;
}

@Injectable()
export class EnumsService {

  private _pricingEnums: any = undefined;

  private _allCountries: CountryEnumerationItem[] = undefined;

  private _countries = [
    {
      name: 'United States',
      value: 'us',
      areaCode: '+1'
    },
    {
      name: 'Spain',
      value: 'es',
      areaCode: '+34'
    },
    {
      name: 'Germany',
      value: 'de',
      areaCode: '+49'
    },
    {
      name: 'South Korea',
      value: 'kr',
      areaCode: '+82'
    },
    {
      name: 'France',
      value: 'fr',
      areaCode: '+33 '
    },
    {
      name: 'Brazil',
      value: 'br',
      areaCode: '+55'
    },
    {
      name: 'Russia',
      value: 'ru',
      areaCode: '+7'
    },
    {
      name: 'China',
      value: 'cn',
      areaCode: '+86'
    }
  ];

  private _turboInsuranceOccupancyTypes = [
    { value: 'Primary', name: "Primary" },
    { value: "SecondHome", name: "Second Home" },
    { value: "Investment", name: "Investment" },
    { value: "Other", name: "Other" },
  ]

  private _turboInsurancePropertyTypes = [
    { value: 'SingleFamily', name: "Single Family" },
    { value: "Townhouse", name: "Townhouse" },
    { value: "Condominium", name: "Condominium" },
    { value: "Cooperative", name: "Cooperative" },
    { value: "TwoToFourUnitProperty", name: "Two To Four Unit Property" },
    { value: "ManufacturedMobileHome", name: "Manufactured Mobile Home" }
  ]

  // SingleFamily = 1,
  // Townhouse,
  // Condominium,
  // Cooperative,
  // TwoToFourUnitProperty,
  // ManufacturedMobileHome

  private _militaryStatusTypes = [
    { value: "isActiveDutyMilitary", name: "Active Duty" },
    { value: "onlyNonActivatedReserveOrNationalGuard", name: "Reserve National Guard Never Activated" },
    { value: "isRetiredDischargedSeparatedFromMilitary", name: "Veteran" },
  ]

  private _ownershipShareTypes = [
    { value: "LessThan25Percent", name: "Less Than 25 Percent" },
    { value: "GreaterOrEqualTo25Percent", name: "Greater Than Or Equal To 25 Percent" },
    { value: "Other", name: "Other" },
  ]

  private _propertyTypes = [
    { value: "SFR", name: "SFR Detached (Use AttachmentType Instead)" },
    { value: "SFRAttached", name: "SFR Attached (Use AttachmentType Instead)" },
    { value: "PUD", name: "PUD (Use IsPlannedUnitDevelopment Instead)" },
    { value: "Condominium", name: "Condominium (Use ProjectType Instead)" },
    { value: "Cooperative", name: "Cooperative (Use ProjectType Instead)" },
    { value: "DetachedCondominium", name: "Detached Condominium (Use AttachmentType & ProjectType Instead)" },
    { value: "Townhouse", name: "2-4 Unit" },
    { value: "HighRiseCondominium", name: "High Rise Condominium (Use ProjectType & ProjectDesignType Instead)" },
    { value: "MultiFamilyResidence", name: "Non Warranted Condominium" },
    { value: "ManufacturedHousing", name: "Manufactured Housing (Use PropertyConstructionMethod Instead)" },
    { value: "Modular", name: "Modular (Use PropertyConstructionMethod Instead)" },
    { value: "ManufacturedHousingSingleWide", name: "ManufacturedHousing SingleWide (Use PropertyConstructionMethod & ManufacturedHomeWidthType Instead)" },
    { value: "ManufacturedHousingDoubleWide", name: "ManufacturedHousing DoubleWide (Use PropertyConstructionMethod & ManufacturedHomeWidthType Instead)" },
    { value: "ManufacturedHousingMultiWide", name: "ManufacturedHousing MultiWide (Use PropertyConstructionMethod & ManufacturedHomeWidthType Instead)" },
    { value: "ManufacturedHomeCondominium", name: "ManufacturedHousing Condominium (Use PropertyConstructionMethod & ProjectType Instead)" },
    { value: "ManufacturedHomeCondominiumOrPUDOrCooperative", name: "ManufacturedHousing Condominium,PUD,Co-op" },
    { value: "Detached", name: "Detached (Use AttachmentType Instead)" },
    { value: "Timeshare", name: "Timeshare" },
    { value: "Other", name: "Other" }
  ]

  private _mortgageTypes = [
    { name: "Conventional", value: "Conventional" },
    { name: "FHA", value: "FHA" },
    { name: "HomeEquity", value: "HomeEquity" },
    { name: "NonConforming", value: "NonConforming" },
    { name: "NonAgency", value: "NonAgency" },
    { name: "Other", value: "Other" },
    { name: "USDA", value: "USDA" },
    { name: "VA", value: "VA" }
  ];

  private _maritalStatusTypes = [
    { name: "Married", value: "Married" },
    { name: "Separated", value: "Separated" },
    { name: "Single", value: "Single" },
    { name: "Other", value: "Other" }
  ];

  private _downPaymentSourceTypes = [
    { name: "Cash On Hand", value: "CashOnHand" },
    { name: "Checkings/Savings", value: "CheckingSavings" },
    { name: "Equity From Pending Sale", value: "EquityOnPendingSale" },
    { name: "Equity From Subject Property", value: "EquityOnSubjectProperty" },
    { name: "Equity on Sold Property", value: "EquityOnSoldProperty" },
    { name: "Gift", value: "GiftFunds" },
    { name: "Other Funds Source", value: "Other" },
    { name: "Sale Of Assets", value: "SaleOfChattel" },
    { name: "Stocks, Bonds & Mutual Funds", value: "StocksAndBonds" }
  ];

  private _reoStatusTypes = [
    { name: "Sold", value: "Sold" },
    { name: "Pending Sale", value: "PendingSale" },
    { name: "Retain For Primary Or Secondary Residence", value: "RetainForPrimaryOrSecondaryResidence" },
    { name: "Rental Property", value: "RentalProperty" },
    { name: "Retained", value: "Retained" }
  ];

  private _usageTypes = [
    { name: "Primary Residence", value: "PrimaryResidence" },
    { name: "Secondary Residence", value: "SecondaryResidence" },
    { name: "Investment", value: "Investment" },
    { name: "Other", value: "Other" }
  ];

  private _militaryBranchOfServiceTypes = [
    { name: "Air Force", value: "AirForce" },
    { name: "Army", value: "Army" },
    { name: "Coast Guard", value: "CoastGuard" },
    { name: "Marines", value: "Marines" },
    { name: "Navy", value: "Navy" },
    { name: "Other", value: "Other" }
  ]

  private _businessTypes = [
    { name: "Sole Proprietor", value: "SoleProprietor" },
    { name: "LLC", value: "LLC" },
    { name: "S-Corp", value: "SCorp" },
    { name: "C-Corp", value: "CCorp" },
    { name: "Partnership", value: "Partnership" }
  ];

  private _assetTypes: Array<{ name: string, value: AssetType }> = [
    { name: "Annuity", value: AssetType.Annuity },
    { name: "Automobile", value: AssetType.Automobile },
    { name: "Bond", value: AssetType.Bond },
    { name: "Bridge Loan Not Deposited", value: AssetType.BridgeLoanNotDeposited },
    { name: "Cash", value: AssetType.Cash },
    { name: "Certificate Of Deposit Time Deposit", value: AssetType.CertificateOfDepositTimeDeposit },
    { name: "Checking Account", value: AssetType.CheckingAccount },
    { name: "Earnest Money Cash Deposit Toward Purchase", value: AssetType.EarnestMoneyCashDepositTowardPurchase },
    { name: "Cash Gift", value: AssetType.CashGift },
    { name: "Gift of Equity", value: AssetType.GiftOfEquity },
    { name: "Individual Development Account", value: AssetType.IndividualDevelopmentAccount },
    { name: "Life Insurance", value: AssetType.LifeInsurance },
    { name: "Money Market Fund", value: AssetType.MoneyMarketFund },
    { name: "Mutual Fund", value: AssetType.MutualFund },
    { name: "Net Worth of Business Owned", value: AssetType.NetWorthOfBusinessOwned },
    { name: "Other Liquid Assets", value: AssetType.OtherLiquidAssets },
    { name: "Other Non Liquid Assets", value: AssetType.OtherNonLiquidAssets },
    { name: "Pending Net Sale Proceeds From Real Estate Assets", value: AssetType.PendingNetSaleProceedsFromRealEstateAssets },
    { name: "Proceeds From Sale Of Non Real Estate Asset", value: AssetType.ProceedsFromSaleOfNonRealEstateAsset },
    { name: "Relocation Money", value: AssetType.RelocationMoney },
    { name: "Retirement Fund", value: AssetType.RetirementFund },
    { name: "Savings Account", value: AssetType.SavingsAccount },
    { name: "Secured Borrowed Funds Not Deposited", value: AssetType.SecuredBorrowedFundsNotDeposited },
    { name: "Stock", value: AssetType.Stock },
    { name: "Stock Options", value: AssetType.StockOptions },
    { name: "Trust Account", value: AssetType.TrustAccount }
  ];

  private _reoPropertyTypes = [
    { name: "Single Family", value: "SingleFamily" },
    { name: "Condo", value: "Condo" },
    { name: "Townhouse", value: "Townhouse" },
    { name: "Coop", value: "Coop" },
    { name: "Two To Four Unit Property", value: "TwoToFourUnitProperty" },
    { name: "Multi-Family / More Than Four Units", value: "MultifamilyMoreThanFourUnits" },
    { name: "Farm", value: "Farm" },
    { name: "Home & Business Combined", value: "HomeAndBusinessCombined" },
    { name: "Land", value: "Land" },
    { name: "Manufactured Mobile Home", value: "ManufacturedMobileHome" },
  ]

  private _propertyWillBeTypes = [
    { name: "Primary Residence", value: "PrimaryResidence" },
    { name: "Second Home", value: "SecondaryResidence" },
    { name: "Investment", value: "Investment" }
  ]

  private _propertyTypesForDeclarations = [
    { name: "Primary Residence", value: "PrimaryResidence" },
    { name: "Second Home", value: "SecondHome" },
    { name: "Investment", value: "Investment" },
    { name: "FHA Secondary Residence", value: "FHASecondaryResidence" }
  ]

  private _heldTitleHows = [
    { name: "Sole", value: "Sole" },
    { name: "Joint With Spouse", value: "JointWithSpouse" },
    { name: "Joint With Other", value: "JointWithOtherThanSpouse" }
  ]

  private _bankruptcyTypes = [
    { name: "Chapter 7", value: "Chapter7" },
    { name: "Chapter 11", value: "Chapter11" },
    { name: "Chapter 12", value: "Chapter12" },
    { name: "Chapter 13", value: "Chapter13" }
  ]

  private _fhaSourceTypes = [
    { name: "Community Non-Profit", value: "CommunityNonProfit" },
    { name: "Employer", value: "Employer" },
    { name: "Federal Agency", value: "FederalAgency" }
  ]

  private _lienTypes = [
    { name: "First Lien", value: "FirstLien" },
    { name: "Second Lien", value: "SecondLien" },
    { name: "Third Lien", value: "ThirdLien" },
    { name: "Fourth Lien", value: "FourthLien" },
    { name: "Other", value: "Other" },
    { name: "HELOC", value: "HELOC" }
  ]

  // ['FirstLien', 'SecondLien', 'Other', 'HELOC', 'FourthLien', 'ThirdLien'],
  private _liabilityTypes = [
    { name: "Alimony", value: "Alimony" },
    { name: "Child Care", value: "ChildCare" },
    { name: "Child Support", value: "ChildSupport" },
    { name: "Collections Judgements And Liens", value: "CollectionsJudgementsAndLiens" },
    { name: "HELOC", value: "HELOC" },
    { name: "Installment", value: "Installment" },
    { name: "Job Related Expenses", value: "JobRelatedExpenses" },
    { name: "Lease Payments", value: "LeasePayments" },
    { name: "Mortgage Loan", value: "MortgageLoan" },
    { name: "Open 30Day Charge Account", value: "Open30DayChargeAccount" },
    { name: "Other Liability", value: "OtherLiability" },
    { name: "Revolving", value: "Revolving" },
    { name: "Separate Maintenance Expense", value: "SeparateMaintenanceExpense" },
    { name: "Other Expense", value: "OtherExpense" },
    { name: "Taxes", value: "Taxes" },
    { name: "Tax Lien", value: "TaxLien" },
    { name: "Borrower Estimated Total Monthly Liability Payment", value: "BorrowerEstimatedTotalMonthlyLiabilityPayment" },
    { name: "Deferred Student Loan", value: "DeferredStudentLoan" },
    { name: "Delinquent Taxes", value: "DelinquentTaxes" },
    { name: "Garnishments", value: "Garnishments" },
    { name: "Homeowners Association Lien", value: "HomeownersAssociationLien" },
    { name: "Monetary Judgment", value: "MonetaryJudgment" },
    { name: "Personal Loan", value: "PersonalLoan" },
    { name: "First Position Mortgage Lien", value: "FirstPositionMortgageLien" },
    { name: "Second Position Mortgage Lien", value: "SecondPositionMortgageLien" },
    { name: "Third Position Mortgage Lien", value: "ThirdPositionMortgageLien" },
    { name: "Unsecured Home Improvement Loan Installment", value: "UnsecuredHomeImprovementLoanInstallment" },
    { name: "Unsecured Home Improvement Loan Revolving", value: "UnsecuredHomeImprovementLoanRevolving" },
    { name: "Car Maintenance", value: "CarMaintenance" },
    { name: "Charitable Contributions", value: "CharitableContributions" },
    { name: "Clothing", value: "Clothing" },
    { name: "Dry Cleaning", value: "Dry Cleaning" },
    { name: "Entertainment", value: "Entertainment" },
    { name: "GroceryToiletry", value: "GroceryToiletry" },
    { name: "Health Insurance", value: "HealthInsurance" },
    { name: "Medical", value: "Medical" },
    { name: "Miscellaneous Living Expenses", value: "MiscellaneousLivingExpenses" },
    { name: "Net Rental Expense", value: "NetRentalExpense" },
    { name: "Payroll Insurance Deduction", value: "PayrollInsuranceDeduction" },
    { name: "Payroll Miscellaneous Deductions", value: "PayrollMiscellaneousDeductions" },
    { name: "Payroll Profit Sharing Deduction", value: "PayrollProfitSharingDeduction" },
    { name: "Payroll Retirement Deduction", value: "PayrollRetirementDeduction" },
    { name: "Payroll Tax Deduction", value: "PayrollTaxDeduction" },
    { name: "Union Dues", value: "UnionDues" }
  ]

  private _loanPurposeTypes = [
    { name: "Refinance", value: "Refinance" },
    { name: "Purchase", value: "Purchase" },
    { name: "Construction Only", value: "ConstructionOnly" },
    { name: "Construction To Perm", value: "ConstructionToPermanent" },
    { name: "Other", value: "Other" }
  ];

  private _companyStatuses = [
    new EnumerationItem('Active', 0),
    new EnumerationItem('Suspended', 1),
    new EnumerationItem('Declined', 2)
  ];

  private _languages: EnumerationItem[] = [
    { name: "English", value: "English" },
    { name: "Spanish", value: "Spanish" },
    { name: "Chinese", value: "Chinese" },
    { name: "Korean", value: "Korean" },
    { name: "Vietnamese", value: "Vietnamese" },
    { name: "Tagalog", value: "Tagalog" },
    { name: "Other", value: "Other" }
  ];

  private _singleChoiceOptionsProviders: EnumerationItem[] = [
    { name: "Loan Purpose", value: "LoanPurpose" },
    { name: "Loan Purpose V2", value: "LoanPurposeV2" },
    { name: "Default Property Type", value: "PropertyType" }
  ];

  private _borrowerCounselingFormatType: EnumerationItem[] = [
    { name: "Face to Face", value: "FaceToFace" },
    { name: "Internet", value: "Internet" },
    { name: "Telephone", value: "Telephone" },
    { name: "Other", value: "Other" }
  ];

  bindToNameAsValue: boolean = true;

  private _enumValues: any = {};

  private _mortgageEnums: any = undefined;

  public get reoStatusTypes() {
    return this._reoStatusTypes;
  }

  public get usageTypes() {
    return this._usageTypes;
  }

  public get languages() {
    return this._languages;
  }

  public get militaryBranchOfServiceTypes() {
    return this._militaryBranchOfServiceTypes;
  }

  public getCompanyStatuses = (): EnumerationItem[] => {
    return this._companyStatuses;
  }
  public get liabilityTypes() {
    return this._liabilityTypes;
  }

  public get lienTypes() {
    return this._lienTypes;
  }

  public get fhaSourceTypes() {
    return this._fhaSourceTypes;
  }

  public get businessTypes() {
    return this._businessTypes;
  }

  public get assetTypes() {
    return this._assetTypes;
  }

  public get bankruptyTypes() {
    return this._bankruptcyTypes;
  }

  public get mortgageTypes() {
    return this._mortgageTypes;
  }

  public get maritalStatusTypes() {
    return this._maritalStatusTypes;
  }

  public get downPaymentSourceTypes() {
    return this._downPaymentSourceTypes;
  }

  public get loanPurposeTypes() {
    return this._loanPurposeTypes;
  }

  public maritalStatusName(value: string | undefined) {
    if (!value) {
      return "";
    }
    const enumItem = this._maritalStatusTypes.find(i => i.value == value);
    if (enumItem) {
      return enumItem.name;
    }
    return "";
  }

  public mortgageTypeName(value: any) {
    if (value !== null && value !== undefined) {
      const enumItem = this._mortgageTypes.find(i => i.value == value);
      if (enumItem) {
        return enumItem.name;
      }
    }
    return '';
  }

  public loanPurposeName(value: any) {
    if (value !== null && value !== undefined) {
      const enumItem = this._loanPurposeTypes.find(i => i.value == value);
      if (enumItem) {
        return enumItem.name;
      }
    }
    return '';
  }

  public residencyStatusTypeName(value: string): string {
    if (value == "USCitizen") {
      return "US Citizen";
    }
    if (value == "PermanentResident") {
      return "Permanent Resident";
    }
    if (value == "NonPermanentResidentAlien") {
      return "Non Permanent Resident Alien";
    }
    return value;
  }

  public assetTypeName(value: AssetType): string {
    var enumItem = this._assetTypes.find(i => i.value == value);
    if (enumItem) {
      return enumItem.name;
    }
    return "";
  }

  public get reoPropertyTypes() {
    return this._reoPropertyTypes;
  }

  public get propertyTypes() {
    return this._propertyTypes;
  }

  public get ownershipShareTypes() {
    return this._ownershipShareTypes;
  }

  public get militaryStatusTypes() {
    return this._militaryStatusTypes;
  }

  public get tueboInsuranceOccupancyTypes() {
    return this._turboInsuranceOccupancyTypes;
  }

  public get tueboInsurancePropertyTypes() {
    return this._turboInsurancePropertyTypes;
  }

  public get propertyWillBeTypes() {
    return this._propertyWillBeTypes;
  }

  public get propertyTypesForDeclarations() {
    return this._propertyTypesForDeclarations;
  }

  public get heldTitleHows() {
    return this._heldTitleHows;
  }

  public get singleChoiceOptionsProviders() {
    return this._singleChoiceOptionsProviders;
  }

  public propertyTypeName(value: any): string {
    if (value !== null && value !== undefined) {
      const enumItem = this._propertyTypes.find(i => i.value == value);
      if (enumItem) {
        return enumItem.name;
      }
    }

    return "";
  }

  public reoPropertyTypeName(value: any): string {
    if (value !== null && value !== undefined) {
      const enumItem = this._reoPropertyTypes.find(i => i.value == value);
      if (enumItem) {
        return enumItem.name;
      }
    }

    return "";
  }

  public propertyWillBeTypeName(value: any): string {
    if (value !== null && value !== undefined) {
      const enumItem = this._propertyWillBeTypes.find(i => i.value == value);
      if (enumItem) {
        return enumItem.name;
      }
    }

    return "";
  }

  private _states = [
    { name: "AL", value: "al" },
    { name: "AK", value: "ak" },
    { name: "AZ", value: "az" },
    { name: "AR", value: "ar" },
    { name: "CA", value: "ca" },
    { name: "CO", value: "co" },
    { name: "CT", value: "ct" },
    { name: "DE", value: "de" },
    { name: "DC", value: "dc" },
    { name: "FL", value: "fl" },
    { name: "GA", value: "ga" },
    { name: "HI", value: "hi" },
    { name: "ID", value: "id" },
    { name: "IL", value: "il" },
    { name: "IN", value: "in" },
    { name: "IA", value: "ia" },
    { name: "KS", value: "ks" },
    { name: "KY", value: "ky" },
    { name: "LA", value: "la" },
    { name: "ME", value: "me" },
    { name: "MD", value: "md" },
    { name: "MA", value: "ma" },
    { name: "MI", value: "mi" },
    { name: "MN", value: "mn" },
    { name: "MS", value: "ms" },
    { name: "MO", value: "mo" },
    { name: "MT", value: "mt" },
    { name: "NE", value: "ne" },
    { name: "NV", value: "nv" },
    { name: "NH", value: "nh" },
    { name: "NJ", value: "nj" },
    { name: "NM", value: "nm" },
    { name: "NY", value: "ny" },
    { name: "NC", value: "nc" },
    { name: "ND", value: "nd" },
    { name: "OH", value: "oh" },
    { name: "OK", value: "ok" },
    { name: "OR", value: "or" },
    { name: "PA", value: "pa" },
    { name: "RI", value: "ri" },
    { name: "SC", value: "sc" },
    { name: "SD", value: "sd" },
    { name: "TN", value: "tn" },
    { name: "TX", value: "tx" },
    { name: "UT", value: "ut" },
    { name: "VT", value: "vt" },
    { name: "VA", value: "va" },
    { name: "WA", value: "wa" },
    { name: "WV", value: "wv" },
    { name: "WI", value: "wi" },
    { name: "WY", value: "wy" },
  ];

  private _stateNames = [
    { value: 'al', name: 'Alabama' },
    { value: 'ak', name: 'Alaska' },
    { value: 'az', name: 'Arizona' },
    { value: 'ar', name: 'Arkansas' },
    { value: 'ca', name: 'California' },
    { value: 'co', name: 'Colorado' },
    { value: 'ct', name: 'Connecticut' },
    { value: 'de', name: 'Delaware' },
    { value: 'dc', name: 'District of Columbia' },
    { value: 'fl', name: 'Florida' },
    { value: 'ga', name: 'Georgia' },
    { value: 'hi', name: 'Hawaii' },
    { value: 'id', name: 'Idaho' },
    { value: 'il', name: 'Illinois' },
    { value: 'in', name: 'Indiana' },
    { value: 'ia', name: 'Iowa' },
    { value: 'ks', name: 'Kansas' },
    { value: 'ky', name: 'Kentucky' },
    { value: 'la', name: 'Louisiana' },
    { value: 'me', name: 'Maine' },
    { value: 'md', name: 'Maryland' },
    { value: 'ma', name: 'Massachusetts' },
    { value: 'mi', name: 'Michigan' },
    { value: 'mn', name: 'Minnesota' },
    { value: 'ms', name: 'Mississippi' },
    { value: 'mo', name: 'Missouri' },
    { value: 'mt', name: 'Montana' },
    { value: 'ne', name: 'Nebraska' },
    { value: 'nv', name: 'Nevada' },
    { value: 'nh', name: 'New Hampshire' },
    { value: 'nj', name: 'New Jersey' },
    { value: 'nm', name: 'New Mexico' },
    { value: 'ny', name: 'New York' },
    { value: 'nc', name: 'North Carolina' },
    { value: 'nd', name: 'North Dakota' },
    { value: 'oh', name: 'Ohio' },
    { value: 'ok', name: 'Oklahoma' },
    { value: 'or', name: 'Oregon' },
    { value: 'pa', name: 'Pennsylvania' },
    { value: 'ri', name: 'Rhode Island' },
    { value: 'sc', name: 'South Carolina' },
    { value: 'sd', name: 'South Dakota' },
    { value: 'tn', name: 'Tennessee' },
    { value: 'tx', name: 'Texas' },
    { value: 'ut', name: 'Utah' },
    { value: 'vt', name: 'Vermont' },
    { value: 'va', name: 'Virginia' },
    { value: 'wa', name: 'Washington' },
    { value: 'wv', name: 'West Virginia' },
    { value: 'wi', name: 'Wisconsin' },
    { value: 'wy', name: 'Wyoming' },
  ]

  public get states() {
    return this._states;
  }

  public get stateNames() {
    return this._stateNames;
  }

  private _residencyBasisValues = [
    { name: "Own", value: "1" },
    { name: "Rent", value: "2" },
    { name: "LiveRentFree", value: "3" }
  ];

  public get residencyBasisValues() {
    return this._residencyBasisValues;
  }

  public get countries() {
    return this._countries;
  }

  public residencyBasisName(value: string): string {
    if (value == "0") {
      return "Own";
    }
    if (value == "1") {
      return "Rent";
    }
    if (value == "2") {
      return "Living Rent Free";
    }
    if (value == "3") {
      return "Unknown";
    }
    return value;
  }

  public get borrowerCounselingFormatTypes() {
    return this._borrowerCounselingFormatType;
  }

  constructor(private readonly _dataService: DataService) { }

  getMortgageEnumerations = (): Observable<any> => {
    if (this._mortgageEnums) {
      return of(this._mortgageEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Enums.Mortgage').pipe(
      map((result) => {
        this._mortgageEnums = result;
        return this._mortgageEnums;
      })
    );
  };

  getEnumValue = (name: string): string => {
    return this._enumValues[name];
  };

  private getEnumerations = (namespace: string): Observable<any> => {
    return this._dataService
      .post(`api/Lookup/enum/namespace/${namespace}?api_key=lookup`, {})
      .pipe(
        map((result) => {
          return this.getEnumerationResult(result);
        })
      );
  };

  private getEnumerationResult = (enumDefinitions: any) => {
    let enums: any = {};
    const allEnumValuesFlattened: any = {};
    for (var enumType in enumDefinitions) {
      var enumeration = enumDefinitions[enumType];
      var pieces = enumType.split(/[\s.]+/);
      var enumName = pieces[pieces.length - 1];
      enums[enumName] = [];
      for (var i = 0; i <= enumeration.length - 1; i++) {
        allEnumValuesFlattened[enumName + '.' + enumeration[i].name] =
          enumeration[i];
        var value = enumeration[i].value;
        if (this.bindToNameAsValue) {
          value = enumeration[i].name;
        }
        if (!enumeration[i].hidden) {
          var convertedEnumeration = new EnumerationItem(
            enumeration[i].displayName,
            value
          );
          convertedEnumeration.alternateValue = enumeration[i].name;
          enums[enumName].push(convertedEnumeration);
        }
        if (!this._enumValues.hasOwnProperty(enumName + enumeration[i].name)) {
          this._enumValues[enumName + enumeration[i].name] = value;
        }
      }
    }
    return enums;
  };

  getPricingEnumerations = (): Observable<any> => {
    if (this._pricingEnums) {
      return of(this._pricingEnums);
    }
    return this.getEnumerations('LE.Lodasoft.Common.Models.Pricing').pipe(
      map((result) => {
        this._pricingEnums = result;
        return this._pricingEnums;
      })
    );
  };

  getAllCountries = (): Observable<CountryEnumerationItem[]> => {
    if (this._allCountries) {
      return of(this._allCountries);
    }

    // countries has been populated from address "https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes/blob/master/all/all.json"
    return this._dataService.getRelativePath('./assets/countries.json').pipe(
      map((result) => {
        this._allCountries = result;
        return this._allCountries;
      })
    );
  }
}
