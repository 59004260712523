import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { EnumsService } from '../../../services/enums.service';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';
import { RealEstateOwned } from '../../../models/mortgage.model';

@Component({
  selector: 'reo-properties',
  templateUrl: 'reo-properties.component.html'
})
export class ReoPropertiesComponent extends ConfigurableFieldHostComponent implements OnInit {

  @Input()
  set reo(reo: RealEstateOwned) {
    this._reo = reo;
    if (!this._reo.dispositionStatus) {
      this._reo.dispositionStatus = 'Retained';
    }
    this.willPropertyBeSold = (this._reo.dispositionStatus === 'PendingSale' ? 'yes' : 'no');
  }

  get reo(): RealEstateOwned {
    return this._reo;
  }

  @ViewChild('reoPropertiesForm')
  reoPropertiesForm: NgForm;

  reoStatusTypes: EnumerationItem[] = [];
  usageTypes: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];

  uniqueId: number;

  protected isMobile: boolean = false;

  protected willPropertyBeSold: string = 'no';

  protected get isInvestmentProperty(): boolean {
    return this.reo.currentUsageType !== 'PrimaryResidence' || this.reo.intendedUsageType !== 'PrimaryResidence';
  }

  private _reo: RealEstateOwned = new RealEstateOwned();

  constructor(private readonly _enumsService: EnumsService) {
    super();
    this.reoStatusTypes = this._enumsService.reoStatusTypes;
    this.usageTypes = this._enumsService.usageTypes;
    this.propertyTypes = this._enumsService.reoPropertyTypes;
    this.uniqueId = Date.now() & 0xffffffff;
    this.uniqueId = this.uniqueId < 0 ? this.uniqueId * -1 : this.uniqueId;
  }

  ngOnInit() {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.configureBasedOnScreenSize(window.innerWidth);
  }

  onPropertyWillBeSoldChanged = () => {
    if (this.willPropertyBeSold === 'no') {
      this.reo.dispositionStatus = 'Retained';
    } else {
      this.reo.dispositionStatus = 'PendingSale';
      this.reo.intendedUsageType = null;
    }
  }

  onPurchasePriceChanged = () => {
    if (!this.reo.purchasePrice) {
      this.reo.purchasePrice = undefined;
    }
  }

  onPropertyValueChanged = () => {
    if (!this.reo.marketValue) {
      this.reo.marketValue = undefined;
    }
  }

  validate = (): boolean => {
    if (this.reoPropertiesForm) {
      this.reoPropertiesForm.form.markAllAsTouched();
      return this.reoPropertiesForm.form.valid;
    }
    return false;
  }

  private configureBasedOnScreenSize(windowWidth: number) {
    this.isMobile = windowWidth < 768
  }
}
