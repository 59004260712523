<ng-template #gatherLeadSource>

  <form class="form w-100" #leadSourceForm="ngForm" novalidate id="leadSourceForm" name="leadSourceForm">
    <ng-container *ngIf="inEditMode">
      <div class="fv-row row">
        <div class="col-sm-4 col-form-label fw-bold fs-6 editor-label">Pick Agent Referral from a List</div>
        <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid">
          <input class="form-check-input h-20px w-30px" type="checkbox" name="pickReferralFromAList"
            id="pickReferralFromAList" [(ngModel)]="step.pickAgentReferralFromAList" />
          <label class="form-check-label" for="pickReferralFromAList">
            {{step.pickAgentReferralFromAList ? "Yes" : "No"}}
          </label>
        </div>
      </div>

      <div *ngIf="availableLeadSourceOptions.length" class="fv-row">
        <div class="col-sm-3 col-form-label fw-bold fs-6 editor-label">Pick Options</div>
        <div class="checkbox mb-3" *ngFor="let availableOption of availableLeadSourceOptions">
          <label class="form-check form-check-inline form-check-solid me-2"
            for="availableOption_{{availableOption.name}}">
            <input id="availableOption_{{availableOption.name}}" name="availableOption_{{availableOption.name}}"
              type="checkbox" (change)="optionsSelectedToPresentToUserChanged(availableOption, $event)"
              [checked]="valuesOfOptionsSelectedToBePresentedToUserAsChoices.includes(availableOption.name.toLowerCase())"
              class="form-check-input">
            <span class="fw-bold ps-2 fs-5">{{availableOption.name}}</span>
          </label>
        </div>
      </div>
    </ng-container>

    <div class="row mb-4">
      <div class="col-md-12 fv-row">
        <label class="form-label mb-2 required">How did you hear about us?</label>
        <select #leadCameFrom="ngModel" class="form-select form-select-solid"
          [ngClass]="{'is-invalid' : leadCameFrom && leadCameFrom.touched && leadCameFrom.invalid}"
          required
          [(ngModel)]="leadSourceType"
          (change)="onLeadSourceChangedType()"
          name="howDidYouHearAboutUs">
          <option [ngValue]="null">--Select One--</option>
          <option *ngFor="let leadSourceOption of step.leadSourceOptions" value="{{leadSourceOption.value}}">{{leadSourceOption.text}}</option>
        </select>
        <div class="invalid-feedback">Please select how you heard about us.</div>
      </div>
    </div>
    <div class="row mb-4" *ngIf="leadSourceType === 'Agents' && step.pickAgentReferralFromAList">
      <div class="col-md-12 fv-row">
        <label class="form-label mb-2">Who was the agent?</label>
        <ng-select
          [(ngModel)]="leadSourceAgent"
          (ngModelChange)="onAgentChanged()"
          [items]="referralAgents"
          bindLabel="name"
          bindValue="id"
          class="ng-select-solid"
          groupBy="company"
          id="lead-source-agent"
          name="leadSourceAgent"
        >
          <ng-template ng-optgroup-tmp let-item="item">
            {{item.company}}
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            {{item.name}}
          </ng-template>
        </ng-select>
        <div class="invalid-feedback">Please select an agent.</div>
      </div>
    </div>
    <div class="row mb-4" *ngIf="leadSourceType &&
      ((leadSourceType !== 'Agents' && leadSourceType !== 'Radio' && leadSourceType !== 'Letter in the Mail' &&
      leadSourceType !== 'Mailer') || (leadSourceType === 'Agents' &&
      (!step.pickAgentReferralFromAList || leadSourceAgent == -1)))">
      <div class="col-md-12 fv-row">
        <label class="form-label mb-2 required">{{referralSourceLabel}}</label>
        <input class="form-control form-control-lg form-control-solid" type="text" name="leadSource" required
          [ngClass]="{'is-invalid' : leadSourceValue && leadSourceValue.touched && leadSourceValue.invalid}" autocomplete="off"
          [(ngModel)]="leadSource" #leadSourceValue="ngModel"
          (ngModelChange)="onLeadSourceTextChanged()"
          (keydown)="onKeyDown($event)"/>
        <div class="invalid-feedback">Please select how you heard about us.</div>
        <span class="text-muted" style="font-style: italic;"> {{noOfCharactersLeft}} characters left</span>
      </div>
    </div>
  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="gatherLeadSource" [step]="step" [stepComponent]="this"></wizard-step-template>
