import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit, Injector, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Navigation, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertType } from 'projects/shared/models/alert-type.enum';
import { Alert } from 'projects/shared/models/alert.model';
import { Borrower } from '../../models/borrower-model';
import { ErrorMessage } from '../../models/error-message.model';
import { ContextBoundStepProperties } from '../../models/wizard/context-bound-step-properties.model';
import { WizardStepBase } from '../../models/wizard/wizard-step-base.model';
import { DataService } from '../../services/data.service';
import { MortgageApplicationService } from '../../services/mortgage-application.service';
import { UtilsService } from '../../services/utils.service';
import { WizardFlowServiceBase } from '../../services/wizard/wizard-flow-service.base';
import { WizardStepTemplateComponent } from './wizard-step-template/wizard-step-template.component';
import { WizardFlowSettings } from '../../models/wizard/config/flow-settings.model';

declare const KTMenu: any;

@Component({
  template: ''
})
export abstract class WizardStepComponentBase<TStep extends WizardStepBase> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(WizardStepTemplateComponent) template: WizardStepTemplateComponent | undefined;

  @Output()
  wizardFlowSettingsChanged: EventEmitter<WizardFlowSettings> = new EventEmitter<WizardFlowSettings>();

  private readonly _location: LocationStrategy;

  protected wizardFlowService: WizardFlowServiceBase;
  protected mortgageApplicationService: MortgageApplicationService;
  protected utilsService: UtilsService;
  protected router: Router;
  protected dataService: DataService;
  protected spinner: NgxSpinnerService;

  public step!: TStep;
  public contextBoundStepProperties!: ContextBoundStepProperties;

  public nextButtonText: string = "Continue";

  public alert: Alert | undefined;

  protected saveMortgageApplicationBeforeNextStep: boolean = false;

  protected cameHereWithBackNavigation: boolean | undefined | null = false;

  constructor(protected readonly injector: Injector) {
    this.wizardFlowService = this.injector.get(WizardFlowServiceBase);
    this.mortgageApplicationService = this.injector.get(MortgageApplicationService);
    this.router = this.injector.get(Router);
    this.dataService = this.injector.get(DataService);
    this.utilsService = this.injector.get(UtilsService);
    this.spinner = this.injector.get(NgxSpinnerService);
    this._location = this.injector.get(LocationStrategy);
    let path = this.router.url.split('?')[0].split('/').pop();

    let navigation: Navigation = this.router.getCurrentNavigation();

    this.cameHereWithBackNavigation = (navigation &&
      navigation.extras &&
      navigation.extras.state &&
      navigation.extras.state.backNavigation);

    const step = this.wizardFlowService.getStep<TStep>(path);
    if (step) {
      this.step = step;
    }
    this._location.onPopState(() => {
      this.wizardFlowService.browserBackOrForwardClicked = true;
      return false;
    });
  }

  ngOnInit() {
    this.alert = undefined;
  }

  ngAfterViewInit() {
    KTMenu.createInstances();
    if (!this.template) {
      return;
    }
  }

  ngOnDestroy(): void {
  }

  onWizardFlowSettingsChanged = (settings: WizardFlowSettings) => {
    this.wizardFlowSettingsChanged.emit(settings);
  }

  isHidden = (fieldName: string): boolean => {
    return this.step.isHidden(fieldName);
  }

  isRequired = (fieldName: string): boolean => {
    return this.step.isRequired(fieldName);
  }

  getLabel = (fieldName: string, defaultLabel: string): string => {
    return this.step.getLabel(fieldName, defaultLabel);
  }

  public borrowerSetting(borrower: Borrower): boolean {
    let result = this.wizardFlowService.context.borrowerSettings.get(borrower.borrowerId);
    if (result) {
      return true;
    } else {
      return false
    }
  }

  protected borrowersToApplyFor(): Borrower[] {
    let borrowersToApplyFor: Borrower[] = [];
    borrowersToApplyFor.push(this.currentBorrower);
    if (this.inCoApplyFlow) {
      return borrowersToApplyFor;
    }
    let coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
      this.currentBorrower.primaryEmail.toLocaleLowerCase());
    for (let b of coBorrowers) {
      if (this.borrowerSetting(b)) {
        borrowersToApplyFor.push(b);
      }
    }
    return borrowersToApplyFor;
  }

  get inCoApplyFlow() {
    return this.wizardFlowService.inCoApplyFlow;
  }

  public get inEditMode() {
    return this.wizardFlowService.inEditMode;
  }

  public get isEditEnabled() {
    return this.wizardFlowService.isEditEnabled;
  }

  protected get mortgageApplication() {
    return this.wizardFlowService.context.mortgage;
  }

  protected get applicationInfo() {
    return this.wizardFlowService.context.applicationInfo;
  }

  protected get currentBorrower() {
    return this.wizardFlowService.context.currentBorrower;
  }

  onBackClicked() {
    this.wizardFlowService.navigateBackward();
    window.scroll(0, 0);
  }

  onNextClicked() {
    if (this.saveMortgageApplicationBeforeNextStep && !this.wizardFlowService.isEditEnabled) {
      this.startSpinner();
      this.wizardFlowService.saveMortgage().subscribe(() => {
        this.stopSpinner();
        this.wizardFlowService.navigateForward();
        window.scroll(0, 0);
      }, err => {
        this.stopSpinner();
        let errorMessage = new ErrorMessage("An error occurred while saving the mortgage", err.error.message);
        this.showError(errorMessage);
        window.scroll(0, 0);
      });
    } else {
      this.wizardFlowService.navigateForward();
      window.scroll(0, 0);
    }
  }

  public stopSpinner = (): void => {
    if (this.template) {
      this.template.stopSpinner();
    } else {
      this.spinner.hide();
    }
  }

  public startSpinner = (): void => {
    if (this.template) {
      this.template.startSpinner();
    } else {
      this.spinner.show();
    }
  }

  public showError = (error: ErrorMessage): void => {
    this.alert = new Alert(error.title, error.message, AlertType.Error);
  }

  public get canMoveToNextStep(): boolean {
    return true;
  }
}
