import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { GatherLeadSourceStepComponent } from "../../wizard/wizard-steps/gather-lead-source-step/gather-lead-source-step.component";
import { WizardStepBase } from "./wizard-step-base.model";
import { Option } from "../../models/wizard/option.model";

export class GatherLeadSourceStep extends WizardStepBase {

  pickAgentReferralFromAList: boolean = true;

  leadSourceOptions: Option[] = [
    new Option('Social Media', 'Social Media', 'Social Media', 'Social Media'),
    new Option('Real Estate Agent', 'Real Estate Agent', 'Real Estate Agent', 'Real Estate Agent'),
    new Option('Internet', 'Internet', 'Internet', 'Internet'),
    new Option('Past Clients', 'Past Clients', 'Past Clients', 'Past Clients'),
    new Option('Radio', 'Radio', 'Radio', 'Radio'),
    new Option('Letter in the Mail', 'Letter in the Mail', 'Letter in the Mail', 'Letter in the Mail'),
    new Option('Other', 'Other', 'Other', 'Other')
  ];

  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = GatherLeadSourceStepComponent;
  }
}

