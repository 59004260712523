<div class="toolbar fv-row" style="min-height: 50px;" *ngIf="isEditEnabled && !inPreviewMode">
  <div class="container-fluid d-flex flex-stack">
    <div *ngIf="inEditMode" class="page-title d-flex align-items-center me-3">

      <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Editing Flow
        <span class="h-20px border-gray-200 border-start mx-4"></span>
      </h1>
      <ul class="breadcrumb breadcrumb-separatorless fw-bold my-1">
        <li class="breadcrumb-item">
          <a (click)="onFlowsClicked()" href="javascript:void(0)" class="link-primary ">Flows</a>
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-200 w-5px h-2px"></span>
        </li>
        <li class="breadcrumb-item text-dark">{{flowTitle}}/{{step.name}}</li>
        <li *ngIf="flowGuid">
          <span class="badge badge-light-success me-auto ">{{flowGuid}}</span>
        </li>
      </ul>
    </div>
    <div *ngIf="!inEditMode" class="page-title d-flex align-items-center me-3">
    </div>
    <div class="d-flex align-items-center py-1">
      <div class="me-4 d-flex">
        <div class="col-sm-3 fs-6 text-dark" style="padding-top: 8px; width:80px">Edit Mode</div>
        <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
          <input class="form-check-input h-20px w-30px" type="checkbox" id="editModeToggle"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="inEditMode"
            (ngModelChange)="onEditModeToggled()" />
          <label class="form-check-label" for="flexSwitch20x30">
            {{inEditMode ? "Yes" : "No"}}
          </label>
        </div>
      </div>
      <div style="padding-top: 8px;">
        <!--begin::Actions-->
        <div class="d-flex">
          <a class="btn btn-sm btn-primary me-3" (click)="onSaveStepConfigClicked()" *ngIf="inEditMode">Save</a>
          <!--begin::Menu-->
          <div class="me-0" *ngIf="!inEditMode">
            <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
              <i class="bi bi-three-dots fs-3"></i>
            </button>
            <!--begin::Menu 3-->
            <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
              data-kt-menu="true">
              <!--begin::Heading-->
              <div class="menu-item px-3">
                <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Flows</div>
              </div>
              <!--end::Heading-->
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <a (click)="onGotoFlowClicked('register')" class="menu-link px-3">Register Flow</a>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <a (click)="onGotoFlowClicked('apply')" class="menu-link px-3">Apply Flow</a>
              </div>
              <!--end::Menu item-->
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <a (click)="onGotoFlowClicked('co-apply')" class="menu-link px-3">Co-Apply Flow</a>
              </div>
              <!--end::Menu item-->
            </div>
          </div>
          <!--begin::Menu-->
          <div class="me-0" *ngIf="inEditMode">
            <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary" data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
              <i class="bi bi-three-dots fs-3"></i>
            </button>
            <!--begin::Menu 3-->
            <div
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
              data-kt-menu="true">
              <!--begin::Heading-->
              <div class="menu-item px-3">
                <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Flow Actions</div>
              </div>
              <!--end::Heading-->
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <a (click)="onEditFlowClicked()" class="menu-link flex-stack px-3">Edit Flow
                  <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                    title="This will open up the graphical flow editor"></i></a>
              </div>
              <!--end::Menu item-->
            </div>
            <!--end::Menu 3-->
          </div>
          <!--end::Menu-->
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </div>
</div>
<div *ngIf="step" class="flex-center bg-white rounded ribbon ribbon-top ribbon-vertical"
  [ngClass]="{'flex-row-fluid' : isNavigationHorizontal, 'flex-column-fluid': !isNavigationHorizontal}">
  <div class="ribbon-label bg-white" *ngIf="!isNavigationApplicable && originator">
    <div class="card">
      <!--begin::Card body-->
      <div class="card-body d-flex flex-center flex-column p-0 pt-2">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px symbol-circle mb-2">
          <img [src]="originator.avatar.includes('male.png') ? 'assets/media/avatars/blank.png' : originator.avatar"
            alt="image" />
        </div>
        <!--end::Avatar-->
        <!--begin::Name-->
        <a class="fs-6 text-gray-800 fw-bolder mb-0">{{originator.firstName + ' ' + originator.lastName}}</a>
        <!--end::Name-->
        <!--begin::Position-->
        <div *ngIf="originator.nmlsId" class="fw-bold text-gray-400 mb-2">NMLS #{{originator.nmlsId}}</div>
        <a *ngIf="originator?.calendarLink"
          class="fs-6 text-hover-primary fw-bolder mb-0"
          [href]="originator?.calendarLink"
          target="_blank">View Calendar
        </a>
        <!--end::Position-->
      </div>
      <!--end::Card body-->
    </div>
  </div>
  <div class="wizard" id="online-application"
    [ngClass]="{'wizard-1' : isNavigationHorizontal || isNavigationTurnedOff, 'wizard-2': isNavigationVertical}">
    <div *ngIf="!isMobile">
      <div class="pt-2" *ngIf="isNavigationTurnedOff"></div>
      <wizard-horizontal-nav [inEditMode]="inEditMode" *ngIf="isNavigationApplicable && isNavigationHorizontal"></wizard-horizontal-nav>
      <wizard-vertical-nav *ngIf="isNavigationApplicable && isNavigationVertical"></wizard-vertical-nav>
    </div>
    <!--begin::Progress-->
    <div *ngIf="!isNavigationApplicable || isMobile">
      <div class="d-flex-center pt-5" *ngIf="!hasProgressBar"></div>
      <div class="d-flex-center pt-5" *ngIf="hasProgressBar">
        <div class="d-flex justify-content-center w-100 mt-auto mb-1">
          <span class="text-gray-700 fs-6">{{percentComplete}}</span>
        </div>
        <div class="d-flex justify-content-center w-100 mt-auto mb-2">
          <div class="h-10px mx-3 bg-light mb-3 rounded" style="width: 450px;">
            <div class="bg-success rounded h-10px rounded" role="progressbar" [style.width]="percentComplete"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Progress-->
    <div class="wizard-body">
      <div [hidden]="showSpinnerOnMainContent" class="row justify-content-center px-20 my-lg-10 px-lg-10 mobile-margins-top-bottom">
        <div class="px-0 explanation-style">
          <div class="fv-row px-20" *ngIf="step!.title && !inEditMode">
            <h2 class="fw-bolder align-items-center text-dark">{{contextBoundStepProperties!.title}}
              <!-- <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i>-->
            </h2>
            <div *ngIf="!inEditMode" class="text-gray-400 fw-bold fs-6 mb-lg-3 ">
              {{contextBoundStepProperties!.explanation}}
            </div>
          </div>
          <div *ngIf="inEditMode" class="fv-row px-20">
            <div class="position-relative d-flex align-items-center" style="margin-top: -14px;padding-bottom:10px">
              <mwl-text-input-autocomplete-container style="width: 100%;" class="autocomplete">
                <input type="text" class="form-control  form-control-solid fs-2 fw-bolder" [triggerCharacter]="'['"
                  [(ngModel)]="step.title" mwlTextInputAutocomplete [findChoices]="findAvailableContextKeywords"
                  [getChoiceLabel]="getSelectedKeyword" />
              </mwl-text-input-autocomplete-container>
            </div>
            <mwl-text-input-autocomplete-container style="width: 100%;padding-bottom:20px" class="autocomplete">
              <input type="text" [triggerCharacter]="'['" mwlTextInputAutocomplete
                [findChoices]="findAvailableContextKeywords" [getChoiceLabel]="getSelectedKeyword"
                class="form-control form-control-solid fw-bold fs-6" [(ngModel)]="step.explanation" />
            </mwl-text-input-autocomplete-container>
          </div>
        </div>
        <div class="fv-row px-20">
          <div class="row">
            <span *ngIf="!inEditMode" class="col-lg-12 text-dark fw-bolder d-block fs-4 pb-2"
              [ngClass]="{'mh-60': contextBoundStepProperties!.heading && contextBoundStepProperties!.heading.length > 0}">
              {{contextBoundStepProperties!.heading}}
            </span>
            <div [ngClass]="{'mh-60': step.heading && step.heading.length > 0}" *ngIf="inEditMode">
              <mwl-text-input-autocomplete-container style="width: 100%;" class="autocomplete">
                <input type="text" class="form-control form-control-solid fs-4 fw-bolder" [triggerCharacter]="'['"
                  style="margin-top: -11px;margin-bottom:10px" [(ngModel)]="step.heading" mwlTextInputAutocomplete
                  [findChoices]="findAvailableContextKeywords" [getChoiceLabel]="getSelectedKeyword" />
              </mwl-text-input-autocomplete-container>
            </div>
          </div>
        </div>
        <div class="fv-row px-20 mb-4 mt-2" *ngIf="inEditMode">
          <div class="d-flex mb-2">
            <label class="fs-6 col-sm-3 col-form-label fw-bold fs-6 editor-label">Navigation Type:</label>
            <div class="col-sm2">
              <select name="navigation" data-control="select2" [(ngModel)]="navType"
                class="form-select form-select-solid form-select-lg">
                <option value="1">Horizontal</option>
                <option value="2">Vertical</option>
                <option value="3">None</option>
              </select>
            </div>
          </div>
          <div class="d-flex mb-2">
            <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Progress Bar on Register Flow:</label>
            <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
              <input class="form-check-input h-20px w-30px" type="checkbox" id="hasProgressBar"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="hasProgressBar" (ngModelChange)="onWizardFlowSettingsChanged()"/>
              <label class="form-check-label" for="hasProgressBar">
                {{hasProgressBar ? "Yes" : "No"}}
              </label>
            </div>
          </div>
        </div>
        <div class="fv-row mb-2"
          *ngIf="inEditMode && !step.hasCustomFieldConfigEditor && fieldsToConfig && fieldsToConfig.length > 0">
          <field-config-editor #fieldsConfigEditor [fieldConfigs]="fieldsToConfig"></field-config-editor>
        </div>
        <div class="fv-row px-20">
          <div class="row">
            <alert [alert]="stepComponent.alert"></alert>
          </div>
          <div class="row row-cols-1" [ngClass]="{'extended-width' : step.path == 'pricing'}">
            <ng-container *ngTemplateOutlet="stepMainTemplate;"></ng-container>
          </div>
        </div>
      </div>
      <div class="text-center mt-4" *ngIf="showSpinnerOnMainContent">
        <i class="fas fa-circle-notch fa-4x fa-spin"></i>
      </div>
    </div>
    <div class="justify-content-center px-20" id="button-container">
      <div class="fv-row px-20" *ngIf="!inPreviewMode">
        <div class="container">
          <div class="row">
            <wizard-button-group [nextButtonText]="stepComponent.nextButtonText" [backVisible]="hasPreviousStep"
              (nextClicked)="onNextClicked()"
              (backClicked)="onBackClicked()"
              [nextVisible]="step!.hasNextStep && step.requiresNextButton" [nextEnabled]="stepComponent.canMoveToNextStep">
            </wizard-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isFirstStepOfRegistrationFlow">
    <div class="col-12 text-gray-400 fw-bold fs-6 login-link">
      Already have an account? <a class="link-primary fw-bolder" (click)="onLoginClicked()">Please login</a>
    </div>
  </div>
  <div class="overlay-layer rounded bg-primary-o-20">
    <div class="spinner spinner-primary"></div>
  </div>
</div>
