<div *ngIf="!isLoading" style="width: 100%; height: 100%;" class="mt-3">
  <iframe id="signing-iframe" *ngIf="signerViewUrl && (!eSignOrder || !eSignOrder.documentSigningVendor || eSignOrder.documentSigningVendor === 'DocuSign' || eSignOrder.documentSigningVendor === 'EncompassPOS')"
    [src]="signerViewUrl" width="100%" height="100%">
  </iframe>

  <iframe id="signing-iframe"
    referrerpolicy="no-referrer-when-downgrade"
    *ngIf="signerViewUrl && eSignOrder && eSignOrder.documentSigningVendor === 'DocMagic'"
    sandbox="allow-forms allow-scripts allow-same-origin allow-downloads allow-popups"
    allow="camera; microphone; display-capture; fullscreen" [src]="signerViewUrl" width="100%" height="100%">
  </iframe>
</div>
